<template>
  <div>
    <div class="row">
      <div class="col d-flex align-items-center">
        <DxCheckBox v-model:value="showArchived" @value-changed="load" />
        <span class="ms-2 pointer" @click="showArchived = !showArchived">Отображать архивные ({{ writsArchivedCount }})</span>
      </div>
      <div class="col">
        <b-button
          variant="success"
          class="btn-label waves-effect waves-light mb-2 float-end"
          :disabled="!hasRightAddEditWrit"
          @click="goToWritCard(0, debtorId)"
        >
          <i class="bx bx-plus label-icon align-middle fs-20 me-2"></i>
          Добавить иск
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <DxDataGrid
          id="gvDebtorsWrits"
          ref="gvDebtorsWrits"
          v-bind="options"
          dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
          class="large-icons"
          :data-source="writsData"
          :column-auto-width="true"
          :wordWrapEnabled="true"
          @toolbar-preparing="onToolbarPreparing($event)"
          @content-ready="onContentReady"
        >
          <DxColumn data-field="preparationDate" data-type="date" caption="Дата составл." sort-order="desc" width="120px" />
          <DxColumn data-field="filingDate" data-type="date" caption="Дата подачи" sort-order="desc" width="120px" />
          <DxColumn data-field="courtDecisionExecutionDocNo" caption="Номер дела" />
          <DxColumn data-field="preparationSumToCollect" caption="Сумма">
            <DxFormat type="fixedPoint" :precision="2" />
          </DxColumn>
          <DxColumn data-field="preparationStateFee" caption="Г/п">
            <DxFormat type="fixedPoint" :precision="2" />
          </DxColumn>

          <DxColumn data-field="preparationPeriodInterval" caption="Период" width="80px" />
          <DxColumn
            data-field="preparationRespondents"
            calculate
            caption="Ответчики"
            :customize-text="makePreparationRespondentsNewLines"
            :encode-html="false"
          />
          <DxColumn data-field="writState" caption="Этап">
            <DxRequiredRule />
            <DxLookup :data-source="writStatesStore" display-expr="name" value-expr="id" />
          </DxColumn>

          <DxColumn data-field="courtDecisionTypeId" caption="Решение">
            <DxLookup :data-source="courtDecisionTypesStore" display-expr="name" value-expr="id" />
          </DxColumn>
          <DxColumn data-field="courtDecisionDate" data-type="date" caption="Дата решения" width="120px" />
          <DxColumn data-field="courtDecisionCancelDate" data-type="date" caption="Отмена" width="90px" />
          <DxColumn data-field="filingReturnDate" caption="Возврат" data-type="date" width="120px" />
          <DxColumn data-field="preparationApplicationFormId" caption="Форма" width="70px">
            <DxLookup :data-source="preparationApplicationFormsStore" display-expr="shortName" value-expr="id" />
          </DxColumn>
          <DxColumn data-field="authorUserId" caption="Создал">
            <DxLookup :data-source="usersStore" display-expr="name" value-expr="id" />
          </DxColumn>
          <DxColumn data-field="edit" caption=" " cell-template="editColumnTemplate" width="190" :showInColumnChooser="false" />
          <!-- templates -->
          <template #editColumnTemplate="{ data: rowInfo }">
            <div>
              <DxButton
                text="Редактировать"
                class="b secondary"
                :disabled="!hasRightAddEditWrit"
                @click="goToWritCard(rowInfo.data.id, debtorId)"
              />
              <DxButton
                text="Удалить"
                class="ms-2 b secondary"
                @click="rowInfo.component.deleteRow(rowInfo.rowIndex)"
                :disabled="!hasRightAddEditWrit"
              />
            </div>
          </template>

          <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
          <template #masterDetailTemplate="{ data: writ }">
            <WritsMasterDetails :template-data="writ" />
          </template>

          <DxColumnChooser :enabled="true" title="Выбор столбцов" mode="select" />
          <DxStateStoring :enabled="true" type="local" storage-key="gvDebtorsWrits6" />
          <DxFilterRow :visible="true" />
          <DxPaging :page-size="20" />
          <DxPager
            :visible="true"
            :allowed-page-sizes="[5, 10, 20, 50]"
            :show-page-size-selector="true"
            :show-navigation-buttons="true"
            :show-info="true"
          />
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { inject } from "vue";
import { authComputed } from "@/state/helpers";
import { courtDecisionTypesStore, writStatesStore } from "@/helpers/common-store/store";
import { DxColumnChooser, DxStateStoring, DxMasterDetail } from "devextreme-vue/data-grid";
import WritsMasterDetails from "./_writsMasterDetails.vue";
import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";

export default {
  name: "WritsComponent",
  components: {
    WritsMasterDetails,
    DxColumnChooser,
    DxStateStoring,
    DxMasterDetail,
  },
  props: {
    debtorId: {
      type: [Number, String],
    },
  },
  setup() {
    var setWritsCount = inject("setWritsCount");

    return {
      setWritsCount,
    };
  },
  computed: {
    ...mapGetters({
      writsArchivedCount: "debtorCardInfo/writsArchivedCount",
    }),

    ...authComputed,
  },
  data() {
    return {
      showArchived: false,
      courtDecisionTypesStore,
      writStatesStore,
      options: settings,
      writsData: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/writs/debtor-writs`,
        deleteUrl: `${process.env.VUE_APP_URL}/api/devextreme/writs/delete`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.data.showArchived = this.showArchived;
          ajaxOptions.data.debtorId = this.debtorId;
        },
        onLoaded: (array) => {
          this.setWritsCount(array.length);
        },
      }),
      usersStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/users/users`,
      }),
      preparationApplicationFormsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/preparation-application-forms`,
      }),
      inhabitantsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/inhabitants/inhabitants-by-debtor?debtorId=` + this.debtorId,
      }),
      calculateFilterExpression(filterValues, selectedFilterOperation) {
        if (!Array.isArray(filterValues)) {
          return ["PreparationRespondents4", "=", filterValues];
        }
        var filterExpression = [];
        for (var i = 0; i < filterValues.length; i++) {
          var filterExpr = [this.dataField, selectedFilterOperation || "=", filterValues[i]];
          if (i > 0) {
            filterExpression.push("or");
          }
          filterExpression.push(filterExpr);
        }
        return filterExpression;
      },
    };
  },
  methods: {
    goToWritCard(id, debtorId) {
      this.$router.push({
        name: "writ-card",
        params: { id: id, debtorId },
      });
    },
    onToolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      let chooserColumn = toolbarItems.filter((r) => r.name == "columnChooserButton")[0];
      chooserColumn.location = "before";
      chooserColumn.showText = "always";
      chooserColumn.options.icon = "bx bx-list-ul";
      chooserColumn.options.elementAttr = {
        class: "b secondary",
      };
    },

    onContentReady(e) {
      var columnChooserView = e.component.getView("columnChooserView");
      if (!columnChooserView._popupContainer) {
        columnChooserView._initializePopupContainer();
        columnChooserView.render();
        columnChooserView._popupContainer.option("position", { of: e.element, my: "left top", at: "left top", offset: "0 50" });
      }
    },
    get(rowInfo) {
      var rowData = rowInfo.row.data;
      if (!rowData) return;
      var texts = [];
      if (rowData.preparationRespondentsIds && rowData.preparationRespondentsIds.length) {
        for (var i = 0; i < rowData.preparationRespondentsIds.length; i++) {
          var value = rowData.preparationRespondentsIds[i];
          var displayText = rowInfo.column.lookup.calculateCellValue(value);
          if (displayText) texts.push(displayText);
        }
      }
      return texts.join(",");
    },
    makePreparationRespondentsNewLines(data) {
      if (!data) return "";
      return data.valueText.replaceAll(",", "<br/>\r\n");
    },
    repaint() {
      this.$refs.gvDebtorsWrits.instance.updateDimensions();
    },
    load() {
      this.$refs["gvDebtorsWrits"].instance.refresh();
    },
  },
};
</script>

<style>
.large-icons .dx-datagrid-group-closed,
.large-icons .dx-datagrid-group-opened {
  font: 25px / 25px DXIcons;
  height: 28px;
}
</style>
