<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { formatDate } from "devextreme/localization";

const props = defineProps(["item"]);
const emits = defineEmits(["editClick", "deleteClick"]);

function fd(d) {
  return formatDate(new Date(d), "dd.MM.yyyy HH:mm");
}

const isUserLog = (item) => item.eventTypeId == 1;
const items = computed(() => (props.item.items ? props.item.items : []));
</script>

<template>
  <div>
    <div class="timeline-year">
      <p>{{ item.year }}</p>
    </div>
    <div class="timeline-continue">
      <TransitionGroup tag="ul" name="list" class="p-0 m-0 list-unstyled">
        <li v-for="i in items" :key="i.id">
          <div class="row timeline-right">
            <div class="col-12">
              <div class="timeline-box">
                <div class="timeline-text">
                  <div class="d-flex flex-column gap-1">
                    <div class="d-flex gap-2 align-items-center">
                      <span class="text-muted fw-lighter">{{ fd(i.createdAt) }}</span>
                      <h6 class="flex-grow mb-0 fw-bold" v-if="!isUserLog(i)">
                        {{ i.eventType }}
                      </h6>
                      <h6 class="flex-grow mb-0 fw-bold" v-else>
                        <u>{{ i.logType }}</u>
                      </h6>
                      <i class="fs-24 bx bx-trash text-danger" role="button" @click="emits('deleteClick', i)" v-if="isUserLog(i)"></i>
                    </div>
                    <div class="d-flex gap-2 align-items-center">
                      <h6 class="mb-0 fw-lighter pre">
                        {{ i.text }}
                      </h6>
                      <i class="fs-24 bx bx-edit-alt text-primary" role="button" @click="emits('editClick', i)" v-if="isUserLog(i)"></i>
                    </div>
                    <div class="d-flex gap-2 align-items-center">
                      <h6 class="text-muted flex-grow mb-0 fw-lighter">Автор: {{ i.userName }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </TransitionGroup>
    </div>
  </div>
</template>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
.pre {
  white-space: pre-wrap;
}
</style>
