<template>
  <div class="placeholder-glow">
    <div class="card-group mt-2">
      <div class="card border-1">
        <div class="card-header">Платежи</div>
        <div class="card-body">
          <ul class="list-group list-group-flush border-dashed">
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    За <b class="text-capitalize">{{ paymentsMonth }}</b>
                  </label>
                </div>
                <div class="flex-shrink-0 ms-2">
                  <a class="btn btn-link fs-12 p-0" @click="showPaymentsAndChargesHistoryPopup"> Архив начислений и платежей </a>
                </div>
              </div>
            </li>
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    Постоянные начисления:
                    <b>{{ f(chargesData.constantCharges) }} руб.</b>
                  </label>
                </div>
              </div>
            </li>
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    Разовые начисления:
                    <b>{{ f(chargesData.singleCharges) }} руб.</b>
                  </label>
                </div>
              </div>
            </li>
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    Текущие начисления:
                    <b>{{ f(chargesData.currentCharges) }} руб.</b>
                  </label>
                </div>
              </div>
            </li>
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    Оплачено:
                    <b>{{ f(paymentsData.actualPaymentsGrouped.sum) }} руб.</b>
                  </label>
                </div>
                <div class="flex-shrink-0 ms-2">
                  <a class="btn btn-link btn-sm fs-12 p-0" @click="showPaymentsDetailsPopup = true">Детализация</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card border-1">
        <div class="card-header">График платежей</div>
        <div class="card-body">
          <apexchart class="apex-charts" height="100%" :series="lastTenPayments" :options="basicLineChart.chartOptions"> </apexchart>
        </div>
      </div>
    </div>

    <PaymentsChargesHistoryModal ref="popupPaymentsChargesHistory" :debtorId="debtorId" :data="paymentsData.paymentsAndChargesHistory" />

    <DxPopupModal
      ref="dxPopupActualPayments"
      v-model:visible="showPaymentsDetailsPopup"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :title="`Детализация оплаченной в ${paymentsMonth} суммы`"
      height="auto"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="h-100 overflow-auto">
        <DxDataGrid
          id="gvPopupActualPaymentsDetails"
          ref="gvPopupActualPaymentsDetails"
          v-bind="gridOptions"
                  dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
          :data-source="paymentsData.actualPaymentsDetails"
        >
          <DxColumn data-field="period" data-type="date" format="MM.yyyy" caption="Период" sort-order="desc" />
          <DxColumn data-field="sum" caption="Сумма">
            <DxFormat type="fixedPoint" :precision="2" />
          </DxColumn>

          <DxPaging :page-size="10" />
          <DxPager
            :visible="true"
            :allowed-page-sizes="[5, 10, 20]"
            :show-page-size-selector="true"
            :show-navigation-buttons="true"
            :show-info="true"
          />
        </DxDataGrid>
      </div>
      <DxPopupToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{
          text: 'Закрыть',
          elementAttr: { class: 'btn-danger waves-effect waves-light' },
          onClick: () => {
            showPaymentsDetailsPopup = false;
          },
        }"
      />
    </DxPopupModal>
  </div>
</template>

<script>
import { inject } from "vue";
import { mapGetters } from "vuex";
import chartSettings from "@/helpers/charts/settings";
import settings from "@/helpers/grid/settings.js";
import { formatNumber } from "devextreme/localization";
import PaymentsChargesHistoryModal from "@/components/shared/payments-charges-history-modal.vue";

export default {
  components: {
    PaymentsChargesHistoryModal,
  },
  props: {
    debtorId: {
      required: true,
    },
  },
  setup() {
    var isLoading = inject("isLoading");

    return {
      isLoading,
    };
  },
  computed: {
    ...mapGetters({
      paymentsData: "debtorCardDebtsAndPaymentsData/paymentsData",
      lastTenPayments: "debtorCardDebtsAndPaymentsData/lastTenPayments",
      paymentsMonth: "debtorCardDebtsAndPaymentsData/paymentsMonth",
      chargesData: "debtorCardDebtsAndPaymentsData/chargesData",
    }),
    loadingCss() {
      return this.isLoading ? "placeholder w-50" : "";
    },
  },
  data() {
    return {
      basicLineChart: chartSettings,
      gridOptions: settings,
      showPaymentsDetailsPopup: false,
      showPaymentsAndChargesPopup: false,
    };
  },
  methods: {
    showPaymentsAndChargesHistoryPopup() {
      this.$refs.popupPaymentsChargesHistory.show();
    },
    repaint() {
      window.dispatchEvent(new Event("resize"));
    },
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
  },
};
</script>

<style></style>
