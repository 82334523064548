<template>
  <div class="placeholder-glow">
    <DebtsInfo :debtor-id="debtorId" />
    <PaymentsInfo :debtor-id="debtorId" />
    <PenaltiesInfo :debtor-id="debtorId" />
  </div>
</template>

<script>
import { inject } from "vue";
import chartSettings from "@/helpers/charts/settings";
import settings from "@/helpers/grid/settings.js";
import DebtsInfo from "./_debtsInfo.vue";
import PaymentsInfo from "./_paymentsInfo.vue";
import PenaltiesInfo from "./_penaltiesInfo.vue";

export default {
  components: {
    DebtsInfo,
    PaymentsInfo,
    PenaltiesInfo,
  },
  props: {
    debtorId: {
      required: true,
    },
  },
  setup() {
    var isLoading = inject("isLoading");
    var setActualDebts = inject("setActualDebts");

    return {
      isLoading,
      setActualDebts,
    };
  },
  computed: {
    loadingCss() {
      return this.isLoading ? "placeholder w-50" : "";
    },
  },
  data() {
    return {
      basicLineChart: chartSettings,
      gridOptions: settings,
    };
  },
  methods: {
    repaint() {
      window.dispatchEvent(new Event("resize"));
    },
  },
};
</script>

<style></style>
