<template>
  <div class="row">
    <div class="col-xl-12">
      <DxDataGrid
        id="gvAgreements"
        ref="gvAgreements"
        v-bind="options"
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
        :data-source="agreementsStore"
        @toolbar-preparing="onToolbarPreparing($event)"
        :remote-operations="false"
        @init-new-row="onInitNewRow"
        @editing-start="onRowUpdating"
        @editor-preparing="onEditorPreparing"
      >
        <DxColumn
          data-field="invalid"
          :allow-sorting="false"
          cell-template="cellInvalidTemplate"
          edit-cell-template="editCellInvalidTemplate"
          caption="Не действует"
        >
          <DxFormItem :col-span="2" />
        </DxColumn>

        <DxColumn data-field="notActiveReason" :visible="false">
          <DxFormItem :col-span="2" :visible="false" />
        </DxColumn>
        <DxColumn data-field="isNotActive" :visible="false">
          <DxFormItem :col-span="2" :visible="false" />
        </DxColumn>
        <DxColumn data-field="notActiveComment" :visible="false">
          <DxFormItem :col-span="2" :visible="false" />
        </DxColumn>

        <DxColumn data-field="inhabitantId" caption="Житель">
          <DxRequiredRule />
          <DxLookup :data-source="inhabitantsStore" display-expr="name" value-expr="id" />
          <DxFormItem :col-span="2" />
        </DxColumn>

        <DxColumn data-field="beginDate" caption="Дата заключения" data-type="date">
          <DxFormItem :editor-options="{ showClearButton: 'true', useMaskBehavior: 'true', placeholder: 'введите или выберите' }" />
        </DxColumn>
        <DxColumn data-field="endDate" caption="Дата окончания" data-type="date">
          <DxFormItem :editor-options="{ showClearButton: 'true', useMaskBehavior: 'true', placeholder: 'введите или выберите' }" />
        </DxColumn>

        <DxColumn data-field="agreementTypeId" caption="Тип">
          <DxRequiredRule />
          <DxLookup :data-source="agreementTypesStore" display-expr="name" value-expr="id" />
        </DxColumn>

        <DxColumn
          data-field="debtsAndPenalties"
          caption="Общая сумма"
          edit-cell-template="debtsAndPenaltiesTemplate"
          cell-template="debtsAndPenaltiesTemplate"
        >
          <DxFormItem :col-span="2" />
        </DxColumn>
        <DxColumn data-field="debts" caption="Долг" :set-cell-value="setDebtsCellValue" edit-cell-template="debtsTemplate">
          <DxFormat type="fixedPoint" :precision="2" />
        </DxColumn>
        <DxColumn data-field="penalties" caption="Пени" :set-cell-value="setPenaltiesCellValue" edit-cell-template="penaltiesTemplate">
          <DxFormat type="fixedPoint" :precision="2" />
        </DxColumn>

        <DxColumn data-field="guaranteedPayment" caption="Гарантированная сумма оплаты">
          <DxFormItem :col-span="2" :editor-options="{ showClearButton: 'true', format: '#,##0.00' }" />
          <DxFormat type="fixedPoint" :precision="2" />
        </DxColumn>
        <DxColumn data-field="note" caption="Примечание">
          <DxFormItem :col-span="2" editor-type="dxTextArea" />
        </DxColumn>

        <DxColumn
          data-field="files"
          :allow-sorting="false"
          cell-template="cellFileTemplate"
          edit-cell-template="editCellFileUpload"
          caption="Документы"
        >
          <DxFormItem :col-span="2" />
        </DxColumn>

        <!-- Templates -->
        <template #debtsAndPenaltiesTemplate="{ data }">
          <div>
            {{ f(Number(data.data.debts || 0) + Number(data.data.penalties || 0)) }}
          </div>
        </template>

        <template #debtsTemplate="{ data }">
          <DxNumberBox
            class="w-100 me-2"
            :show-clear-button="true"
            :value="data.data.debts"
            :disabled="!isDebtsCellEnabled"
            format="#,##0.00"
            @value-changed="(e) => data.setValue(e.value)"
          />
        </template>
        <template #penaltiesTemplate="{ data }">
          <DxNumberBox
            class="w-100 me-2"
            :value="data.data.penalties"
            :disabled="!isPenaltiesCellEnabled"
            :show-clear-button="true"
            format="#,##0.00"
            @value-changed="(e) => data.setValue(e.value)"
          />
        </template>

        <template #cellInvalidTemplate="{ data }">
          <div>
            {{ getNotActiveRowText(data) }}
          </div>
        </template>
        <template #editCellInvalidTemplate="{ data }">
          <div>
            <div>
              <DxCheckBox :value="data.data.isNotActive" @value-changed="setAgreementNotActive($event, data)" />
              <a href="#/" class="underline" @click.prevent="showAgreementInvalidPopup = true"> &nbsp; {{ getNotActiveRowText(data) }} </a>
            </div>
            <AgreementActiveModal
              v-model:show-popup="showAgreementInvalidPopup"
              :data="data.data"
              @save="(dataForm) => onAgreementInvalidSave(dataForm, data)"
            />
          </div>
        </template>

        <template #cellFileTemplate="{ data }">
          <div>
            <div v-for="(item, index) in data.value" :key="index">
              <a href="#" @click.prevent="download(item)">{{ item.fileName }}</a>
            </div>
          </div>
        </template>
        <template #editCellFileUpload="{ data }">
          <div>
            <DxFileUploader
              name="file"
              :multiple="true"
              upload-mode="instantly"
              :chunk-size="200000"
              :upload-url="fileUploadUrl"
              :uploadHeaders="headers"
              @uploaded="(e) => onUploaded(e, data)"
              @upload-error="onUploadError"
            />

            <table class="table">
              <tbody>
                <tr v-for="(item, index) in editingFiles" :key="index">
                  <td style="width: 80px"><i class="bx bxs-file fs-24 text-muted"></i></td>
                  <td>{{ item.fileName }}</td>
                  <td>
                    <a href="#" v-if="item.id != 0" @click.prevent="download(item)">Скачать</a>
                  </td>
                  <td class="justify-content-end text-end">
                    <a href="#" @click.prevent="deleteFile(editingRow.id, item.id)">Удалить</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>

        <DxEditing
          :allow-adding="hasRightAddEditAgreement"
          :allow-updating="hasRightAddEditAgreement"
          :allow-deleting="hasRightAddEditAgreement"
          mode="popup"
        >
          <DxPopup height="auto" width="800" />
        </DxEditing>
        <DxFilterRow :visible="true" />
        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="20" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[5, 10, 20, 50]"
          :show-page-size-selector="true"
          :show-navigation-buttons="true"
          :show-info="true"
        />
      </DxDataGrid>
    </div>
    <CreateOrUpdateInhabitant
      :debtorId="debtorId"
      :id="inhabitantToEditId"
      @on-inhabitant-saved="onInhabitantSaved"
      @on-inhabitant-cancel="onInhabitantSaveCancel"
    />
  </div>
</template>

<script>
import { inject } from "vue";
import axios from "axios";
import { DxPopup, DxFormItem, DxFormat } from "devextreme-vue/data-grid";
import { agreementTypesStore } from "@/helpers/common-store/store";
import { authHeader } from "@/helpers/authservice/auth-header";
import { confirm } from "devextreme/ui/dialog";
import { formatDate, formatNumber } from "devextreme/localization";
import { authComputed } from "@/state/helpers";

import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";
import DxTextArea from "devextreme-vue/text-area";
import DxFileUploader from "devextreme-vue/file-uploader";
import CreateOrUpdateInhabitant from "@/components/forms/create-or-update-inhabitant.vue";
import AgreementActiveModal from "../shared/agreement-active-modal.vue";
import Reasons from "@/helpers/agreements";

const fileUploadUrl = process.env.VUE_APP_URL + "/api/Files/UploadChunk";
const fileDownloadUrl = process.env.VUE_APP_URL + "/api/Files/";
const fileDeleteUrl = process.env.VUE_APP_URL + "/api/devextreme/agreements/delete-file";

export default {
  name: "AgreementsComponent",
  components: {
    DxFormItem,
    DxPopup,
    DxFileUploader,
    DxFormat,
    CreateOrUpdateInhabitant,
    AgreementActiveModal,
  },
  props: {
    debtorId: {
      type: [Number, String],
    },
  },
  setup() {
    var setAgreementsCount = inject("setAgreementsCount");
    var actualDebts = inject("actualDebts");
    return {
      actualDebts,
      setAgreementsCount,
    };
  },
  computed: {
    ...authComputed,
    editingFiles: {
      get() {
        if (this.editingRow == null) return [];
        return this.editingRow.files;
      },
    },
  },
  data() {
    return {
      fileUploadUrl,
      fileDownloadUrl,
      fileDeleteUrl,
      editingRow: null,
      headers: authHeader(),
      options: settings,
      agreementTypesStore,
      DxTextArea,
      dataGrid: null,
      showAgreementInvalidPopup: false,
      inhabitantToEditId: null,
      Reasons,
      isDebtsCellEnabled: true,
      isPenaltiesCellEnabled: true,
      inhabitantsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/inhabitants/inhabitants-by-debtor`,
        onBeforeSend: (_method, ajaxOptions) => {
          ajaxOptions.data.debtorId = this.debtorId;
        },
      }),
      agreementsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/agreements/agreements-by-debtor`,
        insertUrl: `${process.env.VUE_APP_URL}/api/devextreme/agreements/create`,
        updateUrl: `${process.env.VUE_APP_URL}/api/devextreme/agreements/update`,
        deleteUrl: `${process.env.VUE_APP_URL}/api/devextreme/agreements/delete`,
        onBeforeSend: (_method, ajaxOptions) => {
          ajaxOptions.data.debtorId = this.debtorId;
        },
        onLoaded: (array) => {
          this.setAgreementsCount(array.length);
        },
      }),
    };
  },

  methods: {
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },

    onEditorPreparing(e) {
      var that = this;

      if (e.dataField === "agreementTypeId" && e.parentType === "dataRow") {
        this.isDebtsCellEnabled = e.value != 3;
        this.isPenaltiesCellEnabled = e.value != 2;
        const defaultValueChangeHandler = e.editorOptions.onValueChanged;
        e.editorOptions.onValueChanged = function (args) {
          that.isDebtsCellEnabled = args.value != 3;
          that.isPenaltiesCellEnabled = args.value != 2;

          defaultValueChangeHandler(args);
        };
      }
    },
    setDebtsCellValue(newData, value, currentRowData) {
      newData.debts = value;
      newData.debtsAndPenalties = currentRowData.debts + currentRowData.penalties;
    },
    setPenaltiesCellValue(newData, value, currentRowData) {
      newData.penalties = value;
      newData.debtsAndPenalties = currentRowData.debts + currentRowData.penalties;
    },
    calculateDebtsAndPenalties(rowData) {
      return rowData.debts + rowData.penalties;
    },
    getNotActiveRowText(data) {
      let text = "";
      if (data.data.notActiveDate) {
        text = formatDate(new Date(data.data.notActiveDate), "shortDate");
      }
      if (data.data.notActiveReason) {
        text += " " + this.Reasons.find((r) => r.value == data.data.notActiveReason).text;
      }
      if (data.data.notActiveComment) {
        text += " " + data.data.notActiveComment;
      }

      return text;
    },
    setAgreementNotActive(e, data) {
      this.updateCell(data.row.rowIndex, "isNotActive", e.value);
      if (e.value) this.showAgreementInvalidPopup = true;
    },
    onAgreementInvalidSave(formData, data) {
      this.showAgreementInvalidPopup = false;
      this.updateCell(data.row.rowIndex, "notActiveReason", formData.reason);
      this.updateCell(data.row.rowIndex, "notActiveComment", formData.comment);
    },
    updateCell(rowIndex, dataField, value) {
      this.$refs.gvAgreements.instance.cellValue(rowIndex, dataField, value);
    },
    repaint() {
      this.$refs.gvAgreements.instance.updateDimensions();
      this.$refs.gvAgreements.instance.refresh();
    },
    onToolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      let addRowButton = toolbarItems.find((x) => x.name === "addRowButton");
      let self = this;
      if (addRowButton) {
        addRowButton.options.text = "Добавить соглашение";
        addRowButton.showText = "always";
      }

      if (this.hasRightAddEditInhabitant) {
        toolbarItems.push({
          widget: "dxButton",
          options: {
            // visible: false,
            icon: "plus",
            text: "Добавить жителя к лиц. счету",
            onClick: function () {
              self.inhabitantToEditId = 0;
              self.showAgreementInvalidPopup = true;
            },
            elementAttr: {
              class: "b secondary",
            },
          },
          location: "after",
        });
      }
    },

    onInitNewRow(e) {
      e.data.files = [];
      e.data.summ = this.actualDebts;
      this.editingRow = null;
    },
    onRowUpdating(e) {
      this.editingRow = e.data;
    },
    async deleteFile(agreementId, fileId) {
      let dialogResult = await confirm(
        "<i class='dx-icon ri-delete-bin-5-line align-middle fs-22 me-2'></i><span class='fs-5 align-middle'>Вы уверены?</span>",
        "Удаление файлов"
      );

      if (dialogResult) {
        await axios.post(fileDeleteUrl + `/${agreementId}/${fileId}`, { key: agreementId, fileId: fileId });
        this.editingRow.files = this.editingFiles.filter((r) => r.id != fileId);
      }
    },
    download(item) {
      axios.get(fileDownloadUrl + item.id, { responseType: "arraybuffer" }).then(function (response) {
        var headers = response.headers;
        var blob = new Blob([response.data], { type: headers["content-type"] });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.fileName;
        link.click();
      });
    },
    onUploaded(e, cellInfo) {
      var obj = JSON.parse(e.request.responseText);
      var file = { id: 0, tempFileName: obj.tempFileName, fileName: e.file.name };
      var files = cellInfo.data.files;
      files.push(file);
      cellInfo.setValue(files);
    },
    onUploadError(e) {
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Connection refused";
      }
    },
    onInhabitantSaved() {
      this.inhabitantToEditId = null;
      this.$refs.gvAgreements.instance.refresh();
    },
    onInhabitantSaveCancel() {
      this.inhabitantToEditId = null;
    },
  },
};
</script>

<style></style>
