export default {
  chartOptions: {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    markers: {
      size: 8,
    },
    stroke: {
      curve: "straight",
    },
    colors: ["#7da1eb"],
    title: {
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      z: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: true,
      },
    },
  },
};
