<template>
  <div>
    <DxPopupModal
      ref="dxPopupDebts"
      v-model:visible="showPopup"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      title="Архив задолженности"
      height="auto"
      width="75%"
      @shown="shown"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="h-100 overflow-auto placeholder-glow" id="divDebts">
        <div class="w-100 h-100" :class="{ placeholder: isLoading }">
          <DxDataGrid
            id="gvPopupDebtsDetails"
            ref="gvPopupDebtsDetails"
            v-bind="gridOptions"
            :data-source="data"
            dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
          >
            <DxColumn data-field="actualDate" data-type="date" caption="Дата актуальности" sort-order="desc" />
            <DxColumn data-field="debtsOpened" caption="Долг открытый">
              <DxFormat type="fixedPoint" :precision="2" />
            </DxColumn>
            <DxColumn data-field="debtsClosed" caption="Долг закрытый">
              <DxFormat type="fixedPoint" :precision="2" />
            </DxColumn>
            <DxColumn data-field="monthsOpened" caption="Мес. открытый" />
            <DxColumn data-field="monthsClosed" caption="Мес. закрытый" />

            <DxPaging :page-size="10" />
            <DxPager
              :visible="true"
              :allowed-page-sizes="[5, 10, 20]"
              :show-page-size-selector="true"
              :show-navigation-buttons="true"
              :show-info="true"
            />
          </DxDataGrid>
        </div>
      </div>
      <DxPopupToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{
          text: 'Закрыть',
          elementAttr: { class: 'btn-danger waves-effect waves-light' },
          onClick: () => {
            showPopup = false;
          },
        }"
      />
    </DxPopupModal>
  </div>
</template>

<script>
import settings from "@/helpers/grid/settings.js";

export default {
  name: "DebtsHistoryModalComponent",
  props: {
    debtorId: {
      required: true,
    },

    data: {
      default: [],
    },
  },

  data() {
    return {
      showPopup: false,
      isLoading: false,
      gridOptions: settings,
    };
  },

  methods: {
    show() {
      this.showPopup = true;
    },

    shown(e) {
      e.component.repaint();
    },
  },
};
</script>
