<template>
  <div>
    <div class="card border-1">
      <div class="card-body">
        <span>Примечание к лицевому счету</span>
        <div class="d-flex">
          <div class="flex-grow-1">
            <DxTextArea
              :value="debtorNotes"
              @value-changed="changeNotes"
              :show-clear-button="true"
              :disabled="!hasRightAddEditDebtorNotes"
            />
          </div>
          <div class="ps-2 m-auto">
            <b-button
              variant="warning"
              class="btn-label waves-effect waves-light"
              @click="saveNotes"
              :disabled="!hasRightAddEditDebtorNotes"
            >
              <span class="label-icon">
                <SaveIcon class="i" />
              </span>
              <span class="align-middle"> Сохранить </span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-group">
      <div class="card border-1">
        <div class="card-header">Долги</div>
        <div class="card-body">
          <div>
            <ul class="list-group list-group-flush border-dashed">
              <li class="list-group-item ps-0">
                <div class="d-flex align-items-start">
                  <div class="flex-grow-1">
                    <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                      На <b>{{ debtsData.actualDebtsGrouped.actualDate }}</b>
                    </label>
                  </div>
                  <div class="flex-shrink-0 ms-2">
                    <a class="btn btn-link fs-12 p-0" @click="showDebtsHistoryPopup">Архив задолженности</a>
                  </div>
                </div>
              </li>
              <li class="list-group-item ps-0">
                <div class="d-flex align-items-start">
                  <div class="flex-grow-1">
                    <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                      Задолженность:
                      <b>
                        {{ f(debtsData.actualDebtsGrouped.debtsAll) }} руб. (ОЛС {{ f(debtsData.actualDebtsGrouped.debtsOpened) }} руб., ЗЛС
                        {{ f(debtsData.actualDebtsGrouped.debtsClosed) }} руб.)
                      </b>
                    </label>
                  </div>
                </div>
              </li>
              <li class="list-group-item ps-0">
                <div class="d-flex align-items-start">
                  <div class="flex-grow-1">
                    <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                      Неоплачено месяцев:
                      <b>
                        {{ debtsData.actualDebtsGrouped.monthsAll }} мес. (ОЛС {{ debtsData.actualDebtsGrouped.monthsOpened }} мес., ЗЛС
                        {{ debtsData.actualDebtsGrouped.monthsClosed }} мес.)
                      </b>
                    </label>
                  </div>
                </div>
              </li>
              <li class="list-group-item ps-0">
                <div class="d-flex align-items-start">
                  <div class="flex-grow-1">
                    <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                      Периоды:
                      <b style="white-space: pre">
                        {{ debtsData.actualDebtsGrouped.periodsOpened }}
                        {{ debtsData.actualDebtsGrouped.periodsClosed }}
                      </b>
                    </label>
                  </div>
                  <div class="flex-shrink-0 ms-2">
                    <a class="btn btn-link fs-12 p-0 text-muted">Детализация</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card border-1">
        <div class="card-header">График задолженности</div>
        <div class="card-body">
          <apexchart ref="chart" class="apex-charts" height="100%" :series="lastTenDebts" :options="basicLineChart.chartOptions">
          </apexchart>
        </div>
      </div>
      <DebtsHistoryModal ref="popupDebtsHistory" :debtorId="debtorId" :data="debtsData.debtsHistoryGrouped.debtsGrouped" />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { authComputed } from "@/state/helpers";
import { mapActions, mapGetters } from "vuex";
import { formatNumber } from "devextreme/localization";
import chartSettings from "@/helpers/charts/settings";
import settings from "@/helpers/grid/settings.js";
import DebtsHistoryModal from "@/components/shared/debts-history-modal.vue";
import DxTextArea from "devextreme-vue/text-area";
import { SaveIcon } from "@zhuowenli/vue-feather-icons";

export default {
  components: {
    DebtsHistoryModal,
    DxTextArea,
    SaveIcon,
  },
  props: {
    debtorId: {
      required: true,
    },
  },
  setup() {
    var isLoading = inject("isLoading");

    return {
      isLoading,
    };
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      debtorData: "debtorCardInfo/debtorData",
      debtorNotes: "debtorCardInfo/debtorNotes",
      debtsAll: "debtorCardDebtsAndPaymentsData/debtsAll",
      debtsData: "debtorCardDebtsAndPaymentsData/debtsData",
      lastTenDebts: "debtorCardDebtsAndPaymentsData/lastTenDebts",
    }),
    loadingCss() {
      return this.isLoading ? "placeholder w-50" : "";
    },
  },
  data() {
    return {
      notes: null,
      basicLineChart: chartSettings,
      gridOptions: settings,
      showDebtsPopup: false,
    };
  },
  methods: {
    ...mapActions({
      addNotes: "debtorCardInfo/addNotes",
    }),
    ...mapActions({
      alertSuccess: "notification/success",
    }),
    changeNotes(e) {
      this.notes = e.value;
    },
    async saveNotes() {
      this.isLoading = true;
      try {
        await this.addNotes(this.notes);
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    showDebtsHistoryPopup() {
      this.$refs.popupDebtsHistory.show();
    },
    repaint() {
      window.dispatchEvent(new Event("resize"));
    },
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
  },
};
</script>

<style>
.apexcharts-menu-icon {
  display: none;
}
</style>
