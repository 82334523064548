<template>
  <div class="placeholder-glow">
    <div class="card-group mt-2">
      <div class="card border-1">
        <div class="card-header">Пени</div>
        <div class="card-body">
          <ul class="list-group list-group-flush border-dashed">
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    За <b class="text-capitalize">{{ penaltiesMonth }}</b>
                  </label>
                </div>
              </div>
            </li>
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    Всего выставлено пени:
                    <b>{{ f(penaltiesData.actualPenaltiesGrouped.penaltiesBilled) }} руб.</b>
                  </label>
                </div>
              </div>
            </li>
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    Начислено в месяце:
                    <b>{{ f(penaltiesData.actualPenaltiesGrouped.penaltiesCharged) }} руб.</b>
                  </label>
                </div>
              </div>
            </li>
            <li class="list-group-item ps-0">
              <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                  <label class="mb-0 ps-2 align-middle" :class="loadingCss">
                    Оплачено в месяце:
                    <b>{{ f(penaltiesData.actualPenaltiesGrouped.penaltiesReceived) }} руб.</b>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card border-1">
        <div class="card-header">График задолженности по пени</div>
        <div class="card-body">
          <apexchart class="apex-charts" height="100%" :series="lastTenPenalties" :options="basicLineChart.chartOptions"> </apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { mapGetters } from "vuex";
import chartSettings from "@/helpers/charts/settings";
import settings from "@/helpers/grid/settings.js";
import { formatNumber } from "devextreme/localization";

export default {
  components: {},
  props: {
    debtorId: {
      required: true,
    },
  },
  setup() {
    var isLoading = inject("isLoading");

    return {
      isLoading,
    };
  },
  computed: {
    ...mapGetters({
      error: "debtorCardPenaltiesData/error",
      penaltiesData: "debtorCardPenaltiesData/penaltiesData",
      penaltiesMonth: "debtorCardPenaltiesData/penaltiesMonth",
      lastTenPenalties: "debtorCardPenaltiesData/lastTenPenalties",
    }),
    loadingCss() {
      return this.isLoading ? "placeholder w-50" : "";
    },
  },
  data() {
    return {
      basicLineChart: chartSettings,
      gridOptions: settings,
    };
  },
  methods: {
    repaint() {
      window.dispatchEvent(new Event("resize"));
    },
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
  },
};
</script>

<style></style>
