<script setup>
import { defineProps, computed } from "vue";
import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";

const props = defineProps({
  templateData: Object,
});

const preparationNote = computed(() => props.templateData.data.preparationNote);
const courtDecisionNote = computed(() => props.templateData.data.courtDecisionComment);
const id = computed(() => (props.templateData.data.id ? props.templateData.data.id : null));

const executionDocumentsMoves = computed(() =>
  !id.value
    ? null
    : {
        store: createStoreExtend({
          key: "id",
          loadParams: { WritId: id.value },
          loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/writs/writ-execution-documents-moves`,
        }),
      }
);
</script>

<template>
  <div>
    <table class="w-100">
      <tbody>
        <tr>
          <td class="w-half"><span class="fw-bold"> Примечание к Подготовке: </span> {{ preparationNote }}</td>
          <td class="w-half"><span class="fw-bold"> Комментарий к Решению: </span> {{ courtDecisionNote }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <span class="fw-bold"> Исполнение: </span>

      <DxDataGrid
        id="gvInhabitants"
        ref="gvWritsExecutionDocumentsMoves"
        v-bind="settings"
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
        :data-source="executionDocumentsMoves"
        :remote-operations="false"
        :word-wrap-enabled="true"
      >
        <DxColumn data-field="preparationRespondent" caption="ФИО ответчика" />
        <DxColumn data-field="currentPlace" caption="Текущее место" />
        <DxColumn data-field="deliveredSum" caption="Переданная сумма">
          <DxFormat type="fixedPoint" :precision="2" />
        </DxColumn>
        <DxColumn data-field="stateFee" caption="Г/П">
          <DxFormat type="fixedPoint" :precision="2" />
        </DxColumn>
        <DxColumn data-field="penalties" caption="Пени">
          <DxFormat type="fixedPoint" :precision="2" />
        </DxColumn>
        <DxColumn data-field="notes" caption="Примечание" />

        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="20" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[5, 10, 20, 50]"
          :show-page-size-selector="true"
          :show-navigation-buttons="true"
          :show-info="true"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
