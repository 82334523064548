<template>
  <div>
    <DxPopupModal
      :visible="showPopup"
      :drag-enabled="true"
      :show-title="true"
      :width="600"
      title="Укажите причину не действительности соглашения"
      height="auto"
      @showing="showing"
      @hidden="cancel"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="row">
        <div class="col-lg-4">
          <DxRadioGroup layout="vertical" display-expr="text" value-expr="value" :data-source="Reasons" v-model="formData.reason" />
        </div>
        <div class="col-lg-8">
          <label class="form-label">Комментарий:</label>
          <div>
            <DxTextArea class="w-100" :show-clear-button="true" v-model="formData.comment" />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="d-flex gap-3">
          <button class="btn btn-danger btn-sm ms-auto" @click="cancel">Отменить</button>
          <DxButton class="btn-success" id="btnSavePfr" text="Сохранить" @click="save" />
        </div>
      </div>
    </DxPopupModal>
  </div>
</template>

<script>
import DxRadioGroup from "devextreme-vue/radio-group";
import DxTextArea from "devextreme-vue/text-area";
import Reasons from "@/helpers/agreements";

export default {
  name: "AgreementInvalidModal",
  components: {
    DxRadioGroup,
    DxTextArea,
  },
  emits: ["save", "cancel", "update:showPopup"],
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },

  data() {
    return {
      Reasons,
      formData: {
        reason: "complete",
        comment: "",
      },
    };
  },

  methods: {
    showing() {
      if (!this.data) return;
      this.formData.comment = this.data.notActiveComment;
      this.formData.reason = this.data.notActiveReason || "complete";
    },
    async save() {
      // e.preventDefault();

      let data = this.formData;
      try {
        this.$emit("save", data);
        this.resetForm();
      } catch (error) {
        this.message = error;
        this.isError = true;
      }
    },
    cancel() {
      this.$emit("cancel");
      this.$emit("update:showPopup", false);
      this.resetForm();
    },
    resetForm() {
      this.formData = { reason: "complete", comment: "" };
    },
  },
};
</script>
